/* BackgroundEditor.css */

/* Overall container for the background panel */
.background-editor {
    /* If you want a dark panel background, uncomment:
       background-color: #1e1e1e; 
    */
    font-family: Arial, sans-serif;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

/* Each editor-group is spaced and stacked */
.background-editor .editor-group {
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

/* Label styling */
.background-editor .editor-group label {
    font-size: 14px;
    color: #b3b3b3;
}

/* 
    A single container that holds the color swatch + the hex input
    so it appears as one rectangular field
  */
.color-group {
    display: flex;
    align-items: center;
    border: 1px solid #444;
    /* dark border */
    border-radius: 4px;
    background-color: #2a2a2a;
    /* match your dark UI */
    padding: 0 8px;
    height: 36px;
    width: 50%;
    /* same height as standard input */
    box-sizing: border-box;
}

/* Remove default border from color input so it blends with container */
.color-group input[type="color"] {
    border: none;
    background: transparent;
    width: 24px;
    height: 24px;
    margin-right: 8px;
    cursor: pointer;
    padding: 0;
}

/* Remove default swatch border in WebKit browsers */
.color-group input[type="color"]::-webkit-color-swatch-wrapper {
    padding: 0;
}

.color-group input[type="color"]::-webkit-color-swatch {
    border: none;
}

/* The read-only hex field: no border, fill remaining space */
.color-group .color-hex {
    flex: 1;
    border: none;
    background-color: transparent;
    color: #fff;
    font-size: 14px;
    cursor: default;
    outline: none;
    text-align: left;
    padding: 0;
    width: 10%;
}

/* 
    Numeric fields for padding & margin
    (Optional: Adjust width, border, etc.)
  */
.background-editor .editor-group input[type="number"] {
    width: 100px;
    padding: 8px;
    font-size: 14px;
    border: 1px solid #444;
    border-radius: 4px;
    background-color: #2a2a2a;
    color: #fff;
    box-sizing: border-box;
}

/* Example styling for an "Add Image" button */
.add-image-button {
    display: flex;
    padding: 5px 8px;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    border: 1px solid var(--Not-selectionned, #64748B);
    background: var(--Not-selectionned, #64748B);
    
    width: 50%;

    color: var(--Texte---diteur, #CBD5E1);
    text-align: center;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    /* 13.2px */
}

.add-image-button:hover {
    background-color: #4a4a4a;
}

.background-editor-image-preview-wrapper{
display: flex;
align-items: center;
width: 100%;
gap: 1rem;
}

.background-editor-image-preview-wrapper img{
    max-height: 90px;
}