.background-settings-panel .preview {
    margin-top: 10px;
    display: flex;
    flex-direction: column;

    
}

.background-settings-panel img,
.background-settings-panel video {
    max-width: 100%;
    border-radius: 8px;
    margin-top: 10px;
}

.clear-button {
    margin-top: 10px;
    padding: 8px 12px;
    background-color: #d9534f;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.clear-button:hover {
    background-color: #c9302c;
}

.background-preview-wrapper {
    display: flex;
    flex-direction: column !important;
    border: 1px solid var(----dark-gray, #57575B);
    padding: 5px;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    color: var(----editor-text, #D9D9D9);
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.background-preview-wrapper button {
    display: flex;
    padding: 3px 2px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 3px;
    background: var(----dark-gray, #57575B);
    border: none;
    color: var(----editor-text, #D9D9D9);

    font-family: Montserrat;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 100%;
}

.backgroundcolor-preview{
    padding: 0px !important;
    border: none !important;
}

.background-color-preview{
    display: flex;
    flex-direction: row;
    align-items: center;
}



/* Ensure the wrapper is a flex container with centered items */
.color-input-wrapper {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  
  /* Set a fixed height for all children, for example 40px */
  .color-input-wrapper .settings-input,
  .color-input-wrapper .toggle-button {
    height: 40px;
    box-sizing: border-box;
    padding: 0;
    text-align: center;
  }
  
  /* Optionally, you can adjust the widths */
  .color-picker {
    width: 40px;  /* square */

    padding: 0;
    border: none !important;
  }
  
  .color-code-input {
    flex: 1;      /* take remaining space */
    padding: 0 8px;
    width: 80%;

  }
  
  .toggle-button {
    width: 60px;  /* fixed width, adjust as needed */
    background-color: #5C4EFA;

    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    border: none;
    border-radius: 5px;
  }
  