/* SpacingEditor.css */

/* Container to hold everything */
.spacing-editor {
    width: 260px; /* or whatever fits your design */
    margin: 0 auto;
    position: relative;
  }
  
  /* ----- MARGIN BOX ----- */
  .margin-box {
    position: relative;
    background-color: #3a3a3a; /* dark gray for margin area */
    width: 100%;
    height: 160px; /* adjust to your taste */
    box-sizing: border-box;
    border: 1px solid #444; /* optional border line */
  }
  
  /* The margin label (e.g. in top-left corner or center) */
  .margin-label {
    position: absolute;
    top: 4px;
    left: 8px;
    font-size: 12px;
    color: #aaa;
    text-transform: uppercase;
  }
  
  /* Each input is absolutely positioned near the top/left/right/bottom edges */
  .margin-top {
    position: absolute;
    top: 4px;
    left: 50%;
    transform: translateX(-50%);
  }
  .margin-right {
    position: absolute;
    right: 4px;
    top: 50%;
    transform: translateY(-50%);
  }
  .margin-bottom {
    position: absolute;
    bottom: 4px;
    left: 50%;
    transform: translateX(-50%);
  }
  .margin-left {
    position: absolute;
    left: 4px;
    top: 50%;
    transform: translateY(-50%);
  }
  
  /* The numeric inputs themselves */
  .margin-top input,
  .margin-right input,
  .margin-bottom input,
  .margin-left input {
    width: 40px; /* small input */
    background-color: transparent; 
    color: #fff;
    border: none;
    border-radius: 4px;
    text-align: center;
  }
  
  /* ----- PADDING BOX ----- */
  .padding-box {
    position: absolute;
    top: 30px; 
    left: 30px; 
    right: 30px;
    bottom: 30px;
    background-color: #1f1f1f; /* even darker for padding area */
    box-sizing: border-box;
    border: 1px solid #555; /* optional */
  }
  
  /* The "PADDING" label inside the box */
  .padding-label {
    position: absolute;
    top: 4px;
    left: 8px;
    font-size: 12px;
    color: #aaa;
    text-transform: uppercase;
  }
  
  /* The padding inputs, also absolutely positioned */
  .padding-top {
    position: absolute;
    top: 4px;
    left: 50%;
    transform: translateX(-50%);
  }
  .padding-right {
    position: absolute;
    right: 4px;
    top: 50%;
    transform: translateY(-50%);
  }
  .padding-bottom {
    position: absolute;
    bottom: 4px;
    left: 50%;
    transform: translateX(-50%);
  }
  .padding-left {
    position: absolute;
    left: 4px;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .padding-top input,
  .padding-right input,
  .padding-bottom input,
  .padding-left input {
    width: 40px;
    background-color: #2a2a2a;
    color: #fff;
    border: none;
    border-radius: 4px;
    text-align: center;
  }
  