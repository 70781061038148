/* SizeEditor.css */

/* Main container for the editor */
.size-editor {
    display: flex;
    flex-direction: column;
    gap: 12px;
    font-family: Arial, sans-serif;
    color: #fff;
  }
  
  /* Each row: typically two columns (except overflow row) */
  .size-row {
    display: flex;
    gap: 16px;
    align-items: center;
  }
  
  .editor-group {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  
  .editor-group label {
    font-size: 14px;
    color: #b3b3b3;
    margin-bottom: 4px;
  }
  
  /* The container that merges the numeric input + unit dropdown
     into one box with one border + background. */
  .size-input-group {
    display: flex;
    align-items: center;
    position: relative;
    border: 1px solid #444;
    background-color: #2a2a2a;
    border-radius: 4px;
    padding: 0 8px; /* horizontal padding inside the group */
  }
  
  /* The numeric input itself: no separate border or background. */
  .size-input {
    flex: 1;
    background: transparent;
    border: none;
    color: #fff;
    font-size: 14px;
    padding: 6px 0;
    outline: none;
    text-align: left;
    width: 50%;

  }
  
  /* The unit <select>: also transparent, no border, no arrow */
  .unit-select {
    background: transparent;
    border: none;
    color: #fff;
    font-size: 14px;
    outline: none;
    cursor: pointer;
    text-align: right;
    /* Hide default arrow on some browsers */
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  
    margin-left: 4px;
    padding: 6px 0;
  }
  
  /* Style the <option> backgrounds in the dropdown */
  .unit-select option {
    background-color: #2a2a2a;
    color: #fff;
  }
  
  /* The overflow row: label + two buttons for visible/hidden */
  .overflow-row {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: space-between;
  }
  
  .overflow-row label {
    width: 70px;
    color: #b3b3b3;
  }
  
  /* Overflow buttons side by side */
  .overflow-buttons {
    display: flex;
    gap: 8px;
  }
  
  /* Each button for overflow mode */
  .overflow-btn {
    width: 36px;
    height: 36px;
    background: transparent;
    border: none;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.2s ease;
  }
  
  .overflow-btn:hover {
    background-color: #3a3a3a;
  }
  
  /* "Active" state if the current overflow matches that button's mode */
  .overflow-btn.active {
    color: #217bf4;
  }
  
  /* Material icon styling */
  .material-symbols-outlined {
    font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
    font-size: 20px;
  }
  