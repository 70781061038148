/* Topbar and project info */
.topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #313031;
  border-bottom: 1px solid var(----dark-gray, #57575B);
}

.project-info {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  align-items: center;
}

.return-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: rgba(131, 131, 137, 1);
}

.project-details {
  display: flex;
  flex-direction: column;
  line-height: 1.2;
}

.project-name {
  color: var(----editor-text, #D9D9D9);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
}

.project-description {
  font-size: 0.85rem;
}

.project-url {
  color: var(----not-selected, #838389);
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  text-decoration: underline;
  display: flex;
  align-items: baseline;
}
.project-info img{
  max-width: 40px;
}
.project-details-url {
  display: flex;
  align-items: flex-end;
  gap: 5px;
  color: #666;
  font-size: 0.875rem;
}

/* Action and resize controls */
.actions {
  display: flex;
}

.undo-button,
.redo-button,
.preview-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  color: rgba(131, 131, 137, 1);
}

.resize-controls {
  display: flex;
  align-items: center;
  gap: 10px;
}

.resize-button {
  padding: 3px;
  color: rgba(131, 131, 137, 1);
  background: transparent;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.resize-button.selected {
  background: rgba(255, 255, 255, 0.5);
  color: var(----white, #FFF);
}

.resize-buttons {
  display: flex;
  align-items: center;
}

.scale-percentage {
  color: #D9D9D9;
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
}

.input {
  padding: 8px;
  border-radius: 4px;
  width: 80px;
  height: 10px;
  color: rgba(255, 255, 255, 0.72);
  font-family: Montserrat;
  background: rgba(0, 0, 0, 0.45);
  border: none;
}

/* Export section */
.export-section {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
}

.export-section .button {
  display: flex;
  min-width: 106px;
  height: 30px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background: var(----purple, #5C4EFA);
  color: var(----white, #FFF);
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
}

.export-section .export-cloud {
  font-size: 20px;
}

.autosave-status {
  color: var(----editor-text, #D9D9D9);
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
}
