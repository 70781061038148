/* Overlay covers the entire screen */
.support-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

/* Popup container */
.support-popup-content {
  border-radius: 15px;
  background: #FFF;
  box-shadow: 0px 0px 2px 0px rgba(175, 178, 206, 0.56), 0px 1px 4px 0px rgba(4, 4, 52, 0.10);
  padding: 30px 35px;
  border-radius: 10px;
  width: 564px;
  max-width: 90%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  text-align: left;
  font-family: Montserrat;
  display: flex;
  flex-direction: column;
  gap: 10px;

}

/* Title */
.support-popup-content h2 {
  color: var(--black, #131313);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

/* Sub-description text */
.description {
  color: var(--black, #131313);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

/* Form labels */
.label-top {
  display: block;
  margin-bottom: 6px;
  font-weight: bold;
}

/* Textarea for describing the issue */
.issue-textarea {
  width: 100%;
  resize: vertical;
  padding: 8px;
  border: 1px solid #aaa;
  border-radius: 4px;
  margin-bottom: 12px;
}

/* Info text above drop zone */
.attach-text {
  margin: 12px 0 8px 0;
}

/* Drop zone style */
.dropzone {
  border: 2px dashed #CBD5E1;
  border-radius: 6px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 16px;
  transition: background-color 0.3s;
  color: #57575B;
}

.dropzone:hover {
  background-color: #3c3c3c;
}

.preview-image {
  max-width: 100%;
  max-height: 200px;
  display: block;
  margin: 0 auto;
}

/* Contact info text */
.contact-info {
  margin-bottom: 8px;
  line-height: 1.4;
}

.contact-methods {
  display: flex;
  align-items: center;
  gap: 30px;
  justify-content: center;
  margin-bottom: 20px;
}

.contact-methods a {
  display: inline-block;
}

.contact-icon {
  width: 30px;
  /* Adjust size as needed */
  height: auto;
  cursor: pointer;
  transition: opacity 0.2s;
}

.contact-icon:hover {
  opacity: 0.8;
}


/* Button row at bottom */
.button-row {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

/* Cancel & Submit buttons */
.cancel-btn,
.submit-btn {
  background-color: #57575B;
  border: 1px solid #ccc;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s, border-color 0.2s;

  color: var(--white, #FFF);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.cancel-btn:hover {
  background-color: #666;
}

.submit-btn {
  border-color: #5C4EFA;
  background-color: #5C4EFA;
  color: #fff;
}

.submit-btn:hover {
  background-color: #5c4efacc;
  border-color: #5c4efacc;
}

.submit-btn:disabled {
  background-color: #ccc;
  border-color: #ccc;
  cursor: not-allowed;
}

/* Status message */
.status-msg {
  margin-top: 16px;
  font-style: italic;
  text-align: center;
}